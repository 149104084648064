<template>
  <div v-show="isInit">
    <div class="contract_container">
      <div class="top">出货汇总</div>
      <div class="container">
				<div id="zoomBox">
          <div class="contract_main">
            <div class="tips">*如需打印，请至平台网站下载表单打印</div>
            <div class="summary_title">
              <span class="name" v-if="userInfo">{{ userInfo.departName }}（送货清单）</span>
              <div class="sum-rt">
                <div class="date">
                  日期：<span v-if="outOrderDetail && outOrderDetail.orderTime">{{ outOrderDetail.orderTime.split(" ")[0] }}</span>
                </div>
                <div class="order-num">
                  订单号：<span v-if="outOrderDetail && outOrderDetail.orderNo">{{ outOrderDetail.orderNo }}</span>
                </div>
              </div>
            </div>
            <template v-if="customerInfo">
              <div class="userinfo">
                <div class="title">客户信息</div>
                <div class="userinfo_cont">
                  <div v-if="customerInfo">
                    公司名称：{{ customerInfo.departName || '-' }}<br />
                    联系地址：{{ customerInfo.address }}
                  </div>
                  <div class="rt" v-if="customerInfo">
                    联系人：{{ customerInfo.customerName }}<br />
                    联系电话：{{ customerInfo.phone }}
                  </div>
                </div>
              </div>
            </template>

            <div class="project_info" v-if="orderAddressInfo">
              <div class="title">项目信息</div>
              <div class="project_main">
                <div>
                  项目名称：{{ orderAddressInfo.projectName }}<br />
                  送货地址：{{ orderAddressInfo.provinceName }}{{ orderAddressInfo.cityName }}{{ orderAddressInfo.districtName }}
                  {{ orderAddressInfo.address }}
                </div>
                <div style="text-align: right;">
                  收货人：{{ orderAddressInfo.uaRealName }}<br />
                  联系电话：{{ orderAddressInfo.uaMobile }}
                </div>
              </div>
            </div>


            <div class="product_info">
              <table border="0" cellpadding="0" cellspacing="0" class="mytable1">
                <tr>
                  <td width="24%" class="table_bg">产品名称</td>
                  <td width="24%" class="table_bg">规格型号</td>
                  <td width="10%" class="table_bg">单位</td>
                  <td width="10%" class="table_bg">出货数</td>
                  <td width="16%" class="table_bg">签收数</td>
                  <td width="16%" class="table_bg">签收状态</td>
                </tr>
                <tr v-for="(item, index) in productList" :key="index">
                  <td>{{ item.productName }}</td>
                  <td>{{ item.productAttr }}</td>
                  <td>{{ item.productUnit }}</td>
                  <td>{{ item.outNum }}</td>
                  <td>{{ item.signNum }}</td>
                  <td>
                    <template v-if="item.signType == 1">待签收</template>
                    <template v-if="item.signType == 2">已签收</template>
                    <template v-if="item.signType == 3">部分签收</template>
                  </td>
                </tr>
                <tr class="table_foot">
                  <td colspan="3" style="text-align: left;padding-left: 20rpx;"><span class="txt">合计：</span>
                  </td>
                  <td><span class="txt">{{ totalCount }}</span></td>
                  <td colspan="2" style="text-align: right;padding-right: 20rpx;">
                    <template v-if="status == 1">
                      <span class="txt red">待签收</span>
                    </template>
                    <template v-if="status == 2">
                      <span class="txt red">已签收</span>
                    </template>
                    <template v-if="status == 3">
                      <span class="txt red">部分签收</span>
                    </template>
                  </td>
                </tr>
              </table>
              <div class="summary_f">
                <div class="lf">
                  <span class="name" v-if="userInfo">{{ userInfo.departName }}</span>
                  <div class="contact" v-if="userInfo">
                    联系人：{{ userInfo.realname }}，地址：{{ userInfo.address }}<br />
                    电话：{{ userInfo.phone }}
                  </div>
                  <div>
                    <div>经营范围：</div>
                    <div></div>
                  </div>
                </div>
                <div class="rt">
                  <img class="qr" src="../assets/qr.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getQueryString,
} from "@/common/comm.js"
import $http from "@/common/api/request.js"
import { EasyScroller } from 'easyscroller'
export default {
  data() {
    return {
      isInit: false,
      statusBarHeight: 0,
      navigatorBarHeight: 0,
      topH: 0,
      status: "",
      navigatorTitle: "",
      isShowMenu: false,
      sellerIsSign: false, // 卖方是否签字
      shareQueryParams: "",
      orderId: "",
      contId: "",
      contractStatus: "",
      outOrderDetail: null,
      customerInfo: null,
      orderAddressInfo: null,
      userInfo: null,
      productList: [],
      totalCount: 0,
      totalPrice: 0,
      upperCaseTotalPrice: "",
      protocolContent: "",
      isShowProtocolDialog: false,
      saleSignImg: "",
      saleSignTime: "",
      buySignImg: "",
      buySignTime: "",
      sealImg: "",
      salt: "",
      scroller: null
    }
  },
  mounted() {
    let request = getQueryString()
    this.orderId = request['ordId']
    this.salt = request['salt']
    this.getOrderOutAllDetail()
    this.setScroller()
  },
  beforeDestroy() {
		if (this.scroller) this.scroller.destroy()
	},
  methods: {
    getOrderOutAllDetail() {
      let params = {
        url: '/sys/order/orderOutAllPreview?ordId=' + this.orderId,
        method: 'GET',
        header: {
          'Content-Type': 'application/json',
          'X-Access-Token': this.token
        }
      }
      $http.request(params).then((res) => {
        this.isInit = true
        if (res.code == 200) {
          this.outOrderDetail = res.result
          this.customerInfo = res.result.customerInfoVO
          this.userInfo = res.result.userInfoVO
          this.orderAddressInfo = res.result.orderAddressVO
          this.productList = res.result.orderOutItemVO.sysMaterialProductOutVOs
          this.totalCount = res.result.orderOutItemVO.outNum
          // this.calcCount()
          this.status = res.result.orderOutItemVO.signType
          document.title = "出货汇总"
        }
      }).catch(() => { })
    },
    setScroller() {
			const ele = document.querySelector('#zoomBox'); //缩放盒子
			this.scroller = new EasyScroller(ele, {
				scrollingX: true,
				scrollingY: true,
				zooming: true,
				minZoom: 1,    //最小缩放
				maxZoom: 4,    //最大缩放
				zoomLevel: 1, //初始值缩放
				bouncing: false
			});
		},
    calcCount() {
      this.totalCount = 0
      this.totalPrice = 0
      this.productList.forEach((item) => {
        this.totalCount += item.outNum
      })
    }
  }
}
</script>
<style scoped>
.contract_container {
  padding-bottom: 0;
  background-color: #F5F5F5;
  padding-top: 44px;
}

.no-pad {
  padding-bottom: 0 !important;
}

.top {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 10;
	background-color: #fff;
	width: 100%;
	height: 44px;
	text-align: center;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.container {
	height: calc(100vh - 44px);
	overflow: hidden;
}
.status-bar {
  width: 100%;
}

.navgator-bar {
  width: 100%;
  padding: 0 9px;
  box-sizing: border-box;
  /* background-color: #0072DC; */
  color: #242424;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.contract_main {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  /* padding-top: 44px; */
}

.tips {
  text-align: center;
  font-size: 12px;
  color: #ff0000;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #9F9F9F;
  padding: 10px 0;
}

.contract_title {
  font-size: 18rpx;
  font-weight: bold;
  text-align: center;
  padding: 15px 0;
}

.mytable {
  width: 100%;
  border-top: 1px solid #9F9F9F;
  border-bottom: 1px solid #9F9F9F;
}

.mytable td {
  font-size: 12px;
  border-right: 1px solid #9F9F9F;
  padding: 10rpx;
  vertical-align: top;
}

.top_line {
  border-top: 1px solid #9F9F9F;
}

.mytable .b_in {
  display: flex;
  justify-content: space-between;
}

.mytable .noborder {
  border: 0;
}

.project_info {
  display: flex;
  flex-direction: column;
  padding: 7px 8px;
}

.project_info .title {
  font-size: 9px;
  font-weight: bold;
  margin-bottom: 0;
}

.project_main {
  display: flex;
  justify-content: space-between;
  font-size: 6px;
}

.mytable1 {
  width: 100%;
  border-top: 1px solid #9F9F9F;
  /* border-bottom: 1px solid #9F9F9F; */
}

.mytable1 td {
  font-size: 6px;
  border-bottom: 1px solid #9F9F9F;
  padding: 5px;
  vertical-align: top;
  text-align: center;
}

.table_bg {
  background-color: #F6F6F6;
}

.f_bold {
  font-weight: bold;
}

.total_price {
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}

.userinfo {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  border-bottom: 1px solid #9F9F9F;
  background-color: #fff;
}

.userinfo .title {
  font-weight: bold;
  font-size: 10px;
  padding-bottom: 0px;
}

.userinfo_cont {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 6px;
}

.userinfo_cont .rt {
  text-align: right;
}

.sum-rt {
  display: flex;
  font-size: 6px;
}

.summary_title {
  padding: 8px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #9F9F9F;
  background-color: #fff;
}

.summary_title .name {
  font-size: 6px;
  font-weight: bold;
}

.summary_title .date {
  font-size: 6px;
  margin-right: 10px;
}
.order-num {
  font-size: 6px;
}
.summary_f {
  display: flex;
  justify-content: space-between;
  font-size: 6px;
  padding: 10px 10px 24px 10px;
  border-bottom: 1px solid #9F9F9F;
  background-color: #fff;
}

.summary_f .lf {
  padding-right: 8px;
}

.summary_f .name {
  font-size: 9px;
  font-weight: bold;
}

.summary_f .contact {
  margin: 6px 0 10px 0;
}

.summary_f .rt {
  flex: 0 0 80px;
}

.txtinfo {
  font-size: 15px;
}

.ft {
  width: 100%;
  display: flex;
}

.table_foot td {
  background-color: #F6F6F6;
  padding: 10px;
}

.table_foot .txt {
  font-weight: bolder;
}

.red {
  color: red;
}

.qr {
  width: 80px;
  height: 80px;
}
</style>